import i18n from "../Local/i18nSetup";

export default class Language{
    public DisplayName:string;
    public Code:string;

    private constructor(displayName:string,code:string){
        this.DisplayName = displayName;
        this.Code = code;
    }

    static English = new Language("English","en");
    static TraditionalChinese = new Language("Traditional Chinese","zh_hk");
    static SimplifiedChinese = new Language("Simplified Chinese","zh_cn");
    static Chinese = new Language("Chinese","zh");

    static HaveCode = (code:string) => code === Language.English.Code || code === Language.TraditionalChinese.Code || code === Language.SimplifiedChinese.Code;
        
}
