import '../tw.css';
import { useTranslation } from "react-i18next";
import ImgCenteredCaption from '../Components/ImgCenteredCaption';
import Card from '../Components/Card';
import { EmailIcon, FacebookIcon, FacebookMessengerIcon, PhoneIcon } from '../Components/Icons';
import Brand from "../Const/Brand.json";
import { GetBodyFontClass, GetHeaderFontClass } from '../Helper/Helper';

export default function WhereToFindUsPage(){
    const { t } = useTranslation();
    return <div className='mb-10'>
        <ImgCenteredCaption imageSrc={"https://lccccompany.blob.core.windows.net/public/churchBuilding.jpg"} alt="Church Building">
            <h1 className={`md:text-6xl text-2xl text-center text-white ${GetHeaderFontClass()}`}>{t('wheretofindus.title')}</h1>
            <p className={`md:text-2xl text-lg text-center text-white mt-5 ${GetBodyFontClass()}`}>{t('wheretofindus.subTitle')}</p>
        </ImgCenteredCaption>
        {/* Address */}
        <div className='flex justify-center mt-3'>
            <Card classNames='w-full md:w-3/4 mx-5'>
                <h2 className={`text-center font-bold mb-5 ${GetHeaderFontClass("text-2xl md:text-4xl")}`}>{t(`wheretofindus.address.title`)}</h2>
                <div className='flex flex-col md:flex-row justify-center'>
                    <div className='mr-3'>
                        <div className='flex justify-center'>
                            <img src={"https://lccccompany.blob.core.windows.net/public/logo.png"} className="h-16" alt="Church logo"></img>
                        </div>
                        <h5 className={`text-center font-bold ${GetHeaderFontClass()}`}>{t('wheretofindus.address.name')}</h5>
                        <p className={`text-center ${GetBodyFontClass()}`}>{Brand.address.number} {Brand.address.street}</p>
                        <p className={`text-center ${GetBodyFontClass()}`}>{Brand.address.city}</p>
                        {/* <p className={`${GetBodyFontClass()}`}>Leicestershire</p> */}
                        <p className={`text-center ${GetBodyFontClass()}`}>{Brand.address.postcode}</p>
                        <p className={`text-center ${GetBodyFontClass()}`}>{Brand.address.country}</p>
                    </div>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6467.29447526597!2d-1.1159460319740397!3d52.61483899140666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd7808eb01b968f23!2sLeicester%20Chinese%20Christian%20Church!5e0!3m2!1sen!2suk!4v1656241923190!5m2!1sen!2suk"  className='border-0 w-full h-72 md:w-2/3 md:h-96' allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='Church location in Google Map'></iframe>
                </div>
            </Card>
        </div>
        {/* Contact Methods */}
        <div className='flex justify-center mt-3'>
            <Card classNames='w-full md:w-3/4 mx-5'>
                <h2 className={`text-center font-bold mb-5 ${GetHeaderFontClass("text-2xl md:text-4xl")}`}>{t(`wheretofindus.contact.title`)}</h2>
                <table>
                    <tbody>
                        <tr>
                            <td className={`table-cell pr-3 text-right ${GetBodyFontClass()}`}><EmailIcon/>{t(`wheretofindus.contact.emailLabel`)}</td>
                            <td className='table-cell break-all'><a className='underline text-blue-500' href={`mailto:${Brand.contact.email}`}>{Brand.contact.email}</a></td>
                        </tr>
                        <tr>
                            <td className={`table-cell pr-3 text-right ${GetBodyFontClass()}`}><FacebookIcon className='mr-2'/>{t(`wheretofindus.contact.facebookLabel`)}</td>
                            <td className='table-cell break-all'><a className='underline text-blue-500' href={Brand.contact.facebook} target="_blank">{Brand.contact.facebook}</a></td>
                        </tr>
                        <tr>
                            <td className={`table-cell pr-3 text-right ${GetBodyFontClass()}`}><FacebookMessengerIcon className='mr-2'/>{t(`wheretofindus.contact.facebookMessengerLabel`)}</td>
                            <td className='table-cell break-all'><a className='underline text-blue-500' href={Brand.contact.facebookMessenger} target="_blank">{Brand.contact.facebookMessenger}</a></td>
                        </tr>
                        <tr>
                            <td className={`table-cell pr-3 text-right ${GetBodyFontClass()}`}>
                                <PhoneIcon className='mr-2'/>{t(`wheretofindus.contact.phoneLabel`)}
                            </td>
                            <td className='table-cell break-all'>
                                <p className={`${GetBodyFontClass()}`}>{t(`wheretofindus.contact.phones.johntang.name`)}</p>
                                <a className='underline text-blue-500' href={`tel:${Brand.phones.johntang.phone}`}>{Brand.phones.johntang.phone}</a>
                                <p className={`${GetBodyFontClass()}`}>({t(`wheretofindus.contact.phones.johntang.languages`)})</p>
                                <p className={`${GetBodyFontClass()} mt-5`}>{t(`wheretofindus.contact.phones.martinhui.name`)}</p>
                                <a className='underline text-blue-500' href={`tel:${Brand.phones.martinhui.phone}`}>{Brand.phones.martinhui.phone}</a>
                                <p className={`${GetBodyFontClass()}`}>({t(`wheretofindus.contact.phones.martinhui.languages`)})</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Card>
        </div>
    </div>
}
