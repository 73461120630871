import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { solid, brands, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import Brand from "../Const/Brand.json";
import { copyToClipboard } from '../Helper/Helper';


export function CantoneseIcon(props:{className?:string, href?:string, size?:SizeProp}){
    return <GenericFontAwesomeIcon icon={solid('language')} className={`text-teal-600 ${props.className}`} href={props.href} size={props.size}/>;
}

export function EnglishIcon(props:{className?:string, href?:string, size?:SizeProp}){
    return <GenericFontAwesomeIcon icon={solid('language')} className={`text-sky-500 ${props.className}`} href={props.href} size={props.size}/>;
}

export function MandarinIcon(props:{className?:string, href?:string, size?:SizeProp}){
    return <GenericFontAwesomeIcon icon={solid('language')} className={`text-rose-700 ${props.className}`} href={props.href} size={props.size}/>;
}

export function HomeIcon(props:{className?:string, href?:string, size?:SizeProp}){
    return <GenericFontAwesomeIcon className={`mr-3 text-amber-800 ${props.className}`} icon={solid('house')} href={props.href} size={props.size}/>;
}

export function TimeIcon(props:{className?:string, href?:string, size?:SizeProp}){
    return <GenericFontAwesomeIcon className={`mr-3 text-blue-500 ${props.className}`} icon={solid('calendar-week')} href={props.href} size={props.size}/>;
}

export function MapIcon(props:{className?:string, href?:string, size?:SizeProp}){
    return <GenericFontAwesomeIcon className={`mr-3 text-green-500 ${props.className}`} icon={solid('map-location')} href={props.href} size={props.size}/>;
}

export function EmailIcon(props:{className?:string, href?:string, size?:SizeProp}){
    return <GenericFontAwesomeIcon className={`mr-3 text-slate-300 ${props.className}`} icon={solid('envelope')} href={props.href} size={props.size}/>;
}

export function PhoneIcon(props:{className?:string, href?:string, size?:SizeProp}){
    return <GenericFontAwesomeIcon className={`mr-3 text-emerald-500 ${props.className}`} icon={solid('phone')} href={props.href} size={props.size}/>;
}

export function FacebookIcon(props:{className?:string, href?:string, size?:SizeProp}){
    return <GenericFontAwesomeIcon className={`mr-3 text-blue-500 ${props.className}`} icon={brands('facebook')} href={props.href} size={props.size}/>;
}

export function FacebookMessengerIcon(props:{className?:string, href?:string, size?:SizeProp}){
    return <GenericFontAwesomeIcon className={`mr-3 text-violet-500 ${props.className}`} icon={brands('facebook-messenger')} href={props.href} size={props.size}/>;
}

export function DonationIcon(props:{className?:string, href?:string, size?:SizeProp}){
    return <GenericFontAwesomeIcon className={`mr-3 text-amber-500 ${props.className}`} icon={solid('hand-holding-dollar')} href={props.href} size={props.size}/>;
}

export function LinkIcon(props:{className?:string, href?:string, size?:SizeProp}){
    return <GenericFontAwesomeIcon className={`mr-3 text-blue-600 ${props.className}`} icon={solid('up-right-from-square')} href={props.href} size={props.size}/>;
}

export function CopyIcon(props:{className?:string, href?:string, size?:SizeProp, text:string}){
    return <FontAwesomeIcon className={`mr-3 text-base cursor-pointer text-slate-500 ${props.className}`} icon={regular('copy')} onClick={()=>copyToClipboard(props.text)} />
    // return <span className="fa-stack fa-2x">
    //     <FontAwesomeIcon className={``} icon={solid("circle")} size="lg"></FontAwesomeIcon>
    //     <FontAwesomeIcon className={`mr-3 text-base cursor-pointer ${props.className}`} icon={solid('copy')} onClick={()=>copyToClipboard(props.text)} size={"lg"}/>
    // </span>;
}

function GenericFontAwesomeIcon(props:{icon:IconProp, className?:string, href?:string, size?:SizeProp, onClick?:()=>any}){
    return <FontAwesomeIcon className={`${props.className} ${props.href?"cursor-pointer":""}`} icon={props.icon} onClick={()=>props.onClick ? props.onClick() : props.href ? window.open(props.href, "_blank"):null} size={props.size} role="presentation"/>;
}

export function StewardshipIcon(props:{className?:string}){
    return <button className={`cursor-pointer ${props.className} w-48 h-24`} style={{background: `url(https://lccccompany.blob.core.windows.net/public/stewardship.webp)`,backgroundSize:'190px 90px', backgroundRepeat:'no-repeat'}} onClick={()=>window.open(Brand.donation.stewardship, "_blank")} title={`Support us on Stewardship: ${Brand.donation.stewardship}`}></button>;
}

export function AmazonSmileIcon(props:{className?:string}){
    return <button className={`cursor-pointer ${props.className} w-48 h-24`} style={{background: `url(https://lccccompany.blob.core.windows.net/public/smile_amazon.jpg)`,backgroundSize:'190px 90px', backgroundRepeat:'no-repeat'}} onClick={()=>window.open(Brand.donation.amazonSmile, "_blank")} title={`Support us on Stewardship: ${Brand.donation.amazonSmile}`}></button>;
}