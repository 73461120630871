import '../tw.css';
import { useTranslation } from "react-i18next";
import ImgCenteredCaption from '../Components/ImgCenteredCaption';
import Card from '../Components/Card';
import { MapIcon, TimeIcon } from '../Components/Icons';
import { Page } from '../Const/Page';
import { GetBodyFontClass, GetHeaderFontClass, GotoTopOnAnchor } from '../Helper/Helper';
import { useHistory } from 'react-router-dom';

export default function YoungAdultFellowshipPage(){
    const history = useHistory();
    const { t } = useTranslation();
    return <div className='mb-10'>
        <ImgCenteredCaption imageSrc={"https://lccccompany.blob.core.windows.net/public/churchBuilding.jpg"} alt="Church Building">
            <h1 className={`md:text-6xl text-2xl text-center text-white ${GetHeaderFontClass()}`}>{t('studentministry.yaf.title')}</h1>
            {/* <p className={`md:text-2xl text-lg text-center text-white mt-5 ${GetBodyFontClass()}`}>{t('studentministry.yaf.subTitle')}</p> */}
        </ImgCenteredCaption>
        <div className='flex justify-center mt-5 mx-3 md:mx-10'>
            <Card>
                <h3 className={`text-center font-bold ${GetHeaderFontClass()}`}>{t(`studentministry.yaf.title`)}</h3>
                <h5 className={`text-center mt-3 ${GetBodyFontClass()}`}>{t(`studentministry.yaf.subtitle`)}</h5>
                <p className={`text-center mt-3 ${GetBodyFontClass()}`}><TimeIcon/>{t(`studentministry.yaf.time`)}</p>
                <p className={`text-center mt-3 ${GetBodyFontClass()}`}><MapIcon/>{t(`studentministry.yaf.venue`)}</p>
                <a className={`cursor-pointer text-right decoration-solid underline decoration-1 text-blue-500 ${GetBodyFontClass()}`} onClick={(e)=>{history.push(Page.studentMinistry);GotoTopOnAnchor(e)}}>{t(`studentministry.yaf.notsuitable`)}</a>
                <div className='flex md:flex-row flex-col justify-center'>
                    <div className='flex justify-center'>
                        <img src='https://lccccompany.blob.core.windows.net/public/yafPoster.jpg' className='w-3/4 lg:w-2/4'></img>
                    </div>
                    <div className='flex flex-col justify-center'>
                        <img src='https://lccccompany.blob.core.windows.net/public/yafQRcode.jpg' width={600}></img>
                        <p className={`text-center mt-3 ${GetBodyFontClass()}`}>{t(`studentministry.yaf.qrCodeDescription`)}</p>
                    </div>
                </div>
            </Card>
        </div>
    </div>
}
