export enum TimelinePosition{
    alternate=0,
    left=1,
    right=2
}

export function TimelineItem(props:{year?:string, title:string, children?:any, titleClassNames?:string, yearClassNames?:string}){
    let year = props.year;
    return <div>
        {/* Year and title */}
        <div className="flex items-center">
            <span className={`flex-none rounded-full bg-gradient-to-r from-cyan-500 to-blue-500 ${year ? "w-20 h-20 flex items-center justify-center font-bold text-white text-lg text-center" : "w-5 h-5"} ${props.yearClassNames}`}>{year}</span>
            <h3 className={`ml-5 text-4xl ${props.titleClassNames}`}>{props.title}</h3>
        </div>
        {/* Body */}
        <div className="flex flex-row">
            <span className={`border-l-4 border-slate-500/60 ${year ? "ml-8 mr-14" : "ml-2 mr-10"}`} style={{minHeight:"2rem"}}></span>
            <div>{props.children}</div>
        </div>
    </div>
}

export default function Timeline(props:{position:TimelinePosition,children?:any}){
    return <div className="mx-10">
        {props.children}
    </div>
}

