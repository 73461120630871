import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AmazonSmileIcon, DonationIcon, EmailIcon, FacebookIcon, FacebookMessengerIcon, HomeIcon, PhoneIcon, StewardshipIcon } from "./Icons";
import Brand from "../Const/Brand.json";
import { Page } from "../Const/Page";
import { GetBodyFontClass, GetHeaderFontClass, GotoTop } from "../Helper/Helper";
import Language from "../Const/Language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
export default function LcccFooter(){
    const history = useHistory();
    const { t } = useTranslation();
    return <footer className="text-center lg:text-left bg-gray-100 text-gray-600">
        {/* Social Media */}
        <div className="flex justify-center items-center lg:justify-between p-6 border-b border-gray-300">
            <div className={`mr-12 hidden lg:block ${GetBodyFontClass()}`}>
                <span>{t('footer.socialNetwork')}</span>
            </div>
            <div className="flex justify-center">
                <FacebookIcon href={Brand.contact.facebook} size="2x" className="cursor-pointer"></FacebookIcon>
                <FacebookMessengerIcon href={Brand.contact.facebookMessenger} size="2x" className="cursor-pointer"></FacebookMessengerIcon>
            </div>
        </div>
        <div className="mx-6 py-10 text-center md:text-left">
            <div className="grid grid-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                <div className={GetBodyFontClass("text-lg")}>
                    <h6 className={`uppercase mb-4 flex items-center justify-center md:justify-start ${GetHeaderFontClass()}`}>
                        {t('footer.aboutus')}
                    </h6>
                    <p className="mb-4">
                        <a onClick={(e:any)=>{history.push(Page.ourHistory);GotoTop(e)}} className="underline text-blue-500 cursor-pointer">{t('ourHistory.title')}</a>
                    </p>
                    <p className="mb-4">
                        <a onClick={(e:any)=>{history.push(Page.ourBelief);GotoTop(e)}} className="underline text-blue-500 cursor-pointer">{t('ourBelief.title')}</a>
                    </p>
                    <p className="mb-4">
                        <a onClick={(e:any)=>{history.push(Page.ourPastoralTeam);GotoTop(e)}} className="underline text-blue-500 cursor-pointer">{t('pastoralTeam.title')}</a>
                    </p>
                    <p className="mb-4">
                        <a onClick={(e:any)=>{history.push(Page.ourTrustees);GotoTop(e)}} className="underline text-blue-500 cursor-pointer">{t('trustees.title')}</a>
                    </p>
                </div>
                {/* Support Us */}
                <div className={GetBodyFontClass("text-lg")}>
                    <h6 className={`uppercase mb-4 flex items-center justify-center md:justify-start ${GetHeaderFontClass()}`}>
                        {t('footer.supportus.title')}
                    </h6>
                    <p className="mb-4">
                        <a onClick={(e:any)=>{history.push(Page.donation);GotoTop(e)}} className="underline text-blue-500 cursor-pointer"><DonationIcon/>{t('supportUs.title')}</a>
                    </p>
                    {/* Stewardship has ended */}
                    {/* <div className="mb-4 flex justify-center md:justify-start">
                        <StewardshipIcon></StewardshipIcon>
                    </div> */}
                    {/* Amazon Smile has ended */}
                    {/* <div className="mb-4 flex justify-center md:justify-start">
                        <AmazonSmileIcon></AmazonSmileIcon>
                    </div> */}
                </div>
                {/* Useful Links */}
                <div className={GetBodyFontClass("text-lg")}>
                    <h6 className={`uppercase mb-4 flex items-center justify-center md:justify-start ${GetHeaderFontClass()}`}>
                        {t('footer.usefulLinks')}
                    </h6>
                    <p className="mb-4">
                        <a onClick={(e:any)=>{history.push(Page.services);GotoTop(e)}} className="underline text-blue-500 cursor-pointer">{t('service.title')}</a>
                    </p>
                    <p className="mb-4">
                        <a onClick={(e:any)=>{history.push(Page.homeGroups);GotoTop(e)}} className="underline text-blue-500 cursor-pointer">{t('homegroup.title')}</a>
                    </p>
                    <p className="mb-4">
                        <a onClick={(e:any)=>{history.push(Page.childrenMinistry);GotoTop(e)}} className="underline text-blue-500 cursor-pointer">{t('childrenMinistry.title')}</a>
                    </p>
                    <p className="mb-4">
                        <a onClick={(e:any)=>{history.push(Page.youthMinistry);GotoTop(e)}} className="underline text-blue-500 cursor-pointer">{t('youthMinistry.title')}</a>
                    </p>
                    <p className="mb-4">
                        <a onClick={(e:any)=>{history.push(Page.studentMinistry);GotoTop(e)}} className="underline text-blue-500 cursor-pointer">{t('studentministry.title')}</a>
                    </p>
                </div>
                {/* Contact Us */}
                <div className={GetBodyFontClass("text-lg")}>
                    <h6 className={`uppercase mb-4 flex items-center justify-center md:justify-start ${GetHeaderFontClass()}`}>
                        {t('footer.contact')}
                    </h6>
                    <p className={`flex items-center justify-center md:justify-start mb-4 ${GetBodyFontClass("text-lg", Language.English)}`}>
                        <HomeIcon/><a className="underline text-blue-500 cursor-pointer" href={Brand.contact.map} target="_blank">{Brand.address.number} {Brand.address.street}, {Brand.address.city}, {Brand.address.postcode}</a>
                    </p>
                    <p className={`flex items-center justify-center md:justify-start mb-4 ${GetBodyFontClass("text-lg", Language.English)}`}>
                        <EmailIcon/><a className="underline text-blue-500 cursor-pointer" href={Brand.contact.email}>{Brand.contact.email}</a>
                    </p>
                    <p className="flex items-center justify-center md:justify-start mb-4">
                        <PhoneIcon/><a className="underline text-blue-500 cursor-pointer" href={`tel:${Brand.phones.johntang.phone}`} title={`phone Elder John Tang`}>{t('footer.phones.johntang.name')}</a>
                    </p>
                    <p className="flex items-center justify-center md:justify-start mb-4">
                        <PhoneIcon/><a className="underline text-blue-500 cursor-pointer" href={`tel:${Brand.phones.martinhui.phone}`} title={`phone Brother Martin Hui`}>{t('footer.phones.martinhui.name')}</a>
                    </p>
                </div>
            </div>
        </div>
        <div className={`text-center p-6 bg-gray-200 ${GetHeaderFontClass(undefined, Language.TraditionalChinese)}`}>
            <span className="font-black">{t('footer.copyright')} <FontAwesomeIcon icon={regular("copyright")}></FontAwesomeIcon></span>
        </div>
    </footer>
}