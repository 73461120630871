import * as React from 'react';
import '../tw.css';
import { useTranslation } from "react-i18next";
import ImgCenteredCaption from '../Components/ImgCenteredCaption';
import { TimelineAlt, TimelineAltItem } from '../Components/TimelineAlt';
import LCCCCompanyAPI from '../API/LCCCCompanyAPI';
import Sermon from '../Model/Sermon';
import Venue from '../Const/Venue';
import Quotes from '../Components/Quotes';
import { GetBodyFontClass, GetHeaderFontClass } from '../Helper/Helper';
import Card from '../Components/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { MapIcon, TimeIcon } from '../Components/Icons';
import { Page } from '../Const/Page';
import { useHistory } from 'react-router-dom';

export default function EnglishServicePage(){
    const history = useHistory();
    const { t } = useTranslation();
    const [isLoading,setIsLoading] = React.useState<boolean>(false);
    const [sermons, setSermons] = React.useState<Sermon[]>([]);

    React.useEffect(()=>{
        LoadData();
    },[]);
    
    async function LoadData(){
        setIsLoading(true);
        let timetable = await LCCCCompanyAPI.GetSermonTimetable()
        let sermons = timetable.map(x=>Object.assign(new Sermon(),x));
        sermons = sermons.filter(x=>x.venue == Venue.EnglishHall.Code);
        setSermons(sermons);
        setIsLoading(false);

    }

    return <div className='mb-10'>
        <ImgCenteredCaption imageSrc={"https://lccccompany.blob.core.windows.net/public/emhall.jpg"} alt="English Ministry Hall">
            <h1 className={`md:text-6xl text-2xl text-center text-white ${GetHeaderFontClass()}`}>{t('service.english.title')}</h1>
            <p className={`md:text-2xl text-lg text-center text-white mt-5 ${GetBodyFontClass()}`}>{t('service.english.subTitle')}</p>
        </ImgCenteredCaption> 
        {sermons && <div className='flex justify-center mt-5'>
            <TimelineAlt className={``}>
                {sermons.map(sermon => {
                    return <TimelineAltItem start={sermon.start} end={sermon.end} title={sermon.topic} dateClassNames={GetBodyFontClass()} titleClassNames={GetHeaderFontClass()}>
                        <p className={`mb-2 font-bold ${GetBodyFontClass()}`}>{sermon.speaker}</p>
                        <p className={`${GetBodyFontClass()}`}>{sermon.scripture}</p>
                    </TimelineAltItem>;
                })}
            </TimelineAlt>
        </div>}
        {!sermons || sermons.length == 0 && <div className='justify-center flex'>
            <Card classNames='mx-5'>
                <div className='flex flex-col md:flex-row items-center'>
                    <div className="text-center text-orange-400 md:mr-10 p-5 md:mb-10 md:indent-8 drop-shadow-md">
                        <FontAwesomeIcon icon={solid('person-praying')} size="8x"></FontAwesomeIcon>
                        <h2 className={`text-center mt-8 ${GetHeaderFontClass()}`}> {t("service.english.content.title")}</h2>
                    </div>
                    <div className={`flex-1 justify-center  ${GetBodyFontClass()}`}>
                        <h3 className={`text-center mt-3 ${GetBodyFontClass()}`}>{t(`service.english.content.subtitle`)}</h3>
                        <p className={`text-center mt-3 ${GetBodyFontClass()}`}><TimeIcon/>{t(`service.english.content.time`)}</p>
                        <p className={`text-center mt-3 ${GetBodyFontClass()}`}><MapIcon/>{t(`service.english.content.venue`)}</p>
                        <p className='text-right mt-3'>
                            <a className={`cursor-pointer decoration-solid underline decoration-1 text-blue-500 ${GetBodyFontClass()}`}  onClick={()=>history.push(Page.services)}>{t(`service.english.content.notsuitable`)}</a>
                        </p>
                    </div>
                </div>
            </Card>
        </div>}
    </div>
}
