import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import Card, { CardCarousel } from "../Components/Card";
import Carousel from "../Components/Carousel";
import ImgCenteredCaption from "../Components/ImgCenteredCaption";
import { GetBodyFontClass, GetHeaderFontClass } from "../Helper/Helper";
import Data from "../Local/en/Translation.json";

export default function EnglishHomeGroup(){
    const { t } = useTranslation();
    let groupData = Data.homegroup.languages.english.groups;
    let groups = Object.keys(groupData);
    return <div className='mb-10'>
        <ImgCenteredCaption imageSrc={"https://lccccompany.blob.core.windows.net/public/churchBuilding.jpg"} alt="Church Building">
            <h1 className={`md:text-6xl text-2xl text-center text-white ${GetHeaderFontClass()}`}>{t('homegroup.languages.english.name')}</h1>
            <p className={`md:text-2xl text-lg text-center text-white mt-5 ${GetBodyFontClass()}`}>{t('homegroup.languages.english.subTitle')}</p>
        </ImgCenteredCaption>
        <div className='flex justify-center flex-wrap mt-5'>
        {/* imageSrc="https://lccccompany.blob.core.windows.net/public/churchBuilding.jpg" */}
            <HomeGroupCard name={t("homegroup.languages.english.groups.hamilton.name")} imageSources={["https://lccccompany.blob.core.windows.net/public/HamiltonHomeGroup/2.jpg","https://lccccompany.blob.core.windows.net/public/HamiltonHomeGroup/3.jpg","https://lccccompany.blob.core.windows.net/public/HamiltonHomeGroup/4.jpg","https://lccccompany.blob.core.windows.net/public/HamiltonHomeGroup/5.jpg","https://lccccompany.blob.core.windows.net/public/HamiltonHomeGroup/6.jpg","https://lccccompany.blob.core.windows.net/public/HamiltonHomeGroup/7.jpg","https://lccccompany.blob.core.windows.net/public/HamiltonHomeGroup/8.jpg","https://lccccompany.blob.core.windows.net/public/HamiltonHomeGroup/9.jpg"]}></HomeGroupCard>
            <HomeGroupCard name={t("homegroup.languages.english.groups.northling.name")} imageSources={["https://lccccompany.blob.core.windows.net/public/NorthingHomeGroup/Northing1.jpeg","https://lccccompany.blob.core.windows.net/public/NorthingHomeGroup/Northing2.jpeg"]}></HomeGroupCard>
            <HomeGroupCard name={t("homegroup.languages.english.groups.city.name")} imageSources={["https://mdbootstrap.com/img/Photos/Slides/img%20(15).jpg","https://mdbootstrap.com/img/Photos/Slides/img%20(22).jpg","https://mdbootstrap.com/img/Photos/Slides/img%20(23).jpg"]}></HomeGroupCard>
        </div>
    </div>
}

const HomeGroupCard = (props:{name:string, imageSources:string[]}) => {
    const { t } = useTranslation();
    return <CardCarousel classNames='mx-3 mb-3 w-full sm:w-3/4 md:w-2/3 p-0' imageSources={props.imageSources}>
        <div className="mt-5">
            <h2 className={`text-center ${GetHeaderFontClass()}`}>{props.name}</h2>
        </div>
    </CardCarousel>
}