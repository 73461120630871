export default class Venue{
    public DisplayName:string;
    public Code:string;

    private constructor(displayName:string, code:string){
        this.DisplayName = displayName;
        this.Code = code;
    }

    static MainHall = new Venue("Main Hall","mainhall");
    static EnglishHall = new Venue("EM Hall","em");
    static SundaySchoolRoom = new Venue("Sunday School Room","ssr");
}