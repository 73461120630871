///////////////////////////////////////////////////////////////////////////////////////
/// Author: Kin Fung Martin Hui
/// Purpose: This project is a website for the Leicester Chinese Christian Church
///////////////////////////////////////////////////////////////////////////////////////

import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch,Route } from "react-router-dom";
import { Page } from "./Const/Page";
import HomePage from './Pages/HomePage';
import WhoWeArePage from './Pages/WhoWeArePage';
import OurHistoryPage from './Pages/OurHistory';
import OurBeliefPage from './Pages/OurBeliefPage';
import PastoralTeamPage from './Pages/PastoralTeamPage';
import TrusteesPage from './Pages/TrusteesPage';
import WhatDoWeDoPage from './Pages/WhatDoWeDoPage';
import ServicePage from './Pages/ServicePage';
import HomeGroupPage from './Pages/HomeGroupPage';
import ChildrenMinistryPage from './Pages/ChildrenMinistryPage';
import YouthMinistryPage from './Pages/YouthMinistryPage';
import StudentMinistryPage from './Pages/StudentMinistryPage';
import {BackToTopId, BottomBar, TopBar} from './Components/AppBar';

/// CSS framework: Material UI (Typescript)
/// Documentation: https://mui.com/guides/typescript/

/// CSS Library: Tailwind CSS (CSS classes)
/// Documentation: https://tailwindcss.com/
/// Code Samples: https://tailwind-elements.com/docs/standard/forms/switch/

/// Route: React Router Dom (version 5)
/// Documentation: https://v5.reactrouter.com/web/api/Hooks/useroutematch

/// Localization: React i18next
/// Documentation: https://react.i18next.com/getting-started
/// Guide: https://lokalise.com/blog/how-to-internationalize-react-application-using-i18next/

/// Icons: FontAwesome
/// Documentation: https://fontawesome.com/docs/web/use-with/react/

/// Meta Data Generation: React-Helmet 
/// https://www.npmjs.com/package/react-helmet

//Load Localization setup
import './Local/i18nSetup';
import Brand from './Components/Brand';
import EnglishServicePage from './Pages/EnglishServicePage';
import ChineseServicePage from './Pages/ChineseServicePage';
import WhereToFindUsPage from './Pages/WhereToFindUsPage';
import { BrowserStorageKey } from './Const/BrowserStorageKey';
import Language from './Const/Language';
import i18n from './Local/i18nSetup';
import CantoneseHomeGroup from './Pages/CantoneseHomeGroup';
import MandarinHomeGroup from './Pages/MandarinHomeGroup';
import EnglishHomeGroup from './Pages/EnglishHomeGroup';
import YoungAdultFellowshipPage from './Pages/YoungAdultFellowshipPage';
import MandarinStudentFellowshipPage from './Pages/MandarinStudentFellowshipPage';
import LcccFooter from './Components/LcccFooter';
import SupportUsPage from './Pages/SupportUsPage';
import { CantoneseIcon, EnglishIcon, MandarinIcon } from './Components/Icons';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { LanguageSelector } from './Components/LanguageSelector';

export default function App() {
  //On Load
  useEffect(()=>{
    //Update Language
    let language = localStorage.getItem(BrowserStorageKey.Lanaguage);
    if (language) {
      if (Language.HaveCode(language)) {
        i18n.changeLanguage(language);
      }
    }
  },[])

  //Detecting the current language
  const {t} = useTranslation();
  let [isLanguageSelectorOpen, setIsLanguageSelectorOpen] = React.useState<boolean>(false);
  let currentLang = i18n.language;
  let currentLangIcon = currentLang == Language.English.Code ? <EnglishIcon className='mr-1'></EnglishIcon> : currentLang == Language.TraditionalChinese.Code ? <CantoneseIcon className='mr-1'></CantoneseIcon> : <MandarinIcon className='mr-1'></MandarinIcon>;
  let currentLangName = currentLang == Language.English.Code ? t('appbar.links.languageSelector.eng') : currentLang == Language.TraditionalChinese.Code ? t('appbar.links.languageSelector.zhhk') : t('appbar.links.languageSelector.zhcn');
  
  const onLanguageSelect = (code:string) => {
    i18n.changeLanguage(code);
    localStorage.setItem(BrowserStorageKey.Lanaguage,code);
  }
  return (
    <Router>
      <div id={`${BackToTopId}`} className={`absolute top-0`}></div>
      <TopBar className='!hidden lg:!block'></TopBar>
      <div className='lg:hidden w-full justify-center'>
        <Brand className='w-full justify-center '/>
        {/* Langauge Selector */}
        <LanguageSelector></LanguageSelector>
      </div>
      
      <Switch>
        <Route path={Page.whoWeAre}><WhoWeArePage/></Route>
        <Route path={Page.ourHistory}><OurHistoryPage/></Route>
        <Route path={Page.ourBelief}><OurBeliefPage/></Route>
        <Route path={Page.ourPastoralTeam}><PastoralTeamPage/></Route>
        <Route path={Page.ourTrustees}><TrusteesPage/></Route>
        <Route path={Page.donation}><SupportUsPage/></Route>
        <Route path={Page.whatDoWeDo}><WhatDoWeDoPage/></Route>
        <Route path={Page.services}><ServicePage/></Route>
        <Route path={Page.engService}><EnglishServicePage/></Route>
        <Route path={Page.chineseService}><ChineseServicePage/></Route>
        <Route path={Page.homeGroups}><HomeGroupPage/></Route>
        <Route path={Page.englishHomeGroups}><EnglishHomeGroup/></Route>
        <Route path={Page.cantoneseHomeGroups}><CantoneseHomeGroup/></Route>
        <Route path={Page.mandarinHomeGroups}><MandarinHomeGroup/></Route>
        <Route path={Page.childrenMinistry}><ChildrenMinistryPage/></Route>
        <Route path={Page.youthMinistry}><YouthMinistryPage/></Route>
        <Route path={Page.studentMinistry}><StudentMinistryPage/></Route>
        <Route path={Page.youngAdultFellowship}><YoungAdultFellowshipPage/></Route>
        <Route path={Page.mandarinStudentFellowship}><MandarinStudentFellowshipPage/></Route>
        <Route path={Page.whereToFindUs}><WhereToFindUsPage/></Route>
        <Route path={Page.home}><HomePage/></Route>
        {/* <Route path={Page.all}><HomePage/></Route> */}
      </Switch>
      <LcccFooter></LcccFooter>
      <BottomBar className='lg:!hidden'></BottomBar>
    </Router>
  );
}
