///////////////////////////////////////////////////////////////////////////////////////
/// Author: Kin Fung Martin Hui
/// Purpose: This project is a website for the Leicester Chinese Christian Church
///////////////////////////////////////////////////////////////////////////////////////
import * as React from 'react';
import '../tw.css';
import { useTranslation } from "react-i18next";
import ImgCenteredCaption from '../Components/ImgCenteredCaption';
import { Helmet } from "react-helmet";
import { Page } from '../Const/Page';
import ImageButton from '../Components/ImageButton';
import { useHistory } from 'react-router-dom';
import { FacebookIcon, LinkIcon } from '../Components/Icons';
import Brand from "../Const/Brand.json";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { GetBodyFontClass, GetHeaderFontClass, GotoTop } from '../Helper/Helper';
import { zIndex } from 'material-ui/styles';
import BannerVideo from "../video/BannerMontage.mp4";

export default function HomePage(){
    const history = useHistory();
    const { t } = useTranslation();
    const bannerVideo = React.useRef<HTMLVideoElement>(null);

    //On Page Load
    React.useEffect(()=>{
        bannerVideo.current?.play();
    },[])

    return <div className='mb-5'>
        <Helmet>
            {/* OG Meta Data for Social Media: https://ogp.me/ */}
            <meta charSet="utf-8" />
            <meta name="robots" content="nofollow" />
            <meta name="author" content="Leiecester Chinese Christian Church"/>
            <meta name="og:title" content={t('home.meta.title')}/>
            <meta name="description" content={t('home.meta.description')}/>
            <meta name="og:description" content={t('home.meta.description')}/>
            <meta name="og:image" content="https://lccccompany.blob.core.windows.net/public/logo.png"/>
            <meta name="og:url" content={`${window.location.origin}${Page.home}`}></meta>
            <meta name="og:type" content="profile"></meta>
            <meta name="geo.region" content="UK"></meta>
            <meta name="geo.placename" content="Leicester"></meta>
            <meta name="geo.position" content="52.6179693;-1.1163557"></meta>
        </Helmet>
        {/* Banner Video */}
        <div className={`relative text-center`}>
            <video ref={bannerVideo} loop={true} controlsList="nodownload" className='w-full opacity-50' style={{height:"calc(100vw/16*9)"}} muted={true}>
                <source src={BannerVideo} type="video/mp4" ></source>
            </video>
            <div className='absolute w-full h-full top-0 items-center justify-center z-0 hidden sm:!flex'>
                <div className='bg-zinc-500/60 rounded-lg p-5 mx-10 sm:mx-10 md:mx-24 lg:mx-48'>
                    <h1 className={`md:text-6xl text-2xl text-center text-white ${GetHeaderFontClass()}`}>{t('home.title')}</h1>
                    <p className={`md:text-2xl text-lg text-center text-white mt-5 ${GetBodyFontClass()}`}>{t('home.subTitle')}</p>
                </div>
            </div>
            <div className='p-3 mx-10 sm:mx-10 md:mx-24 lg:mx-48 sm:hidden'>
                <h1 className={`md:text-6xl text-2xl text-center ${GetHeaderFontClass()}`}>{t('home.title')}</h1>
                <p className={`md:text-2xl text-lg text-center mt-5 ${GetBodyFontClass()}`}>{t('home.subTitle')}</p>
            </div>
        </div>
        {/* News */}
        <NewsDiv classNames=''></NewsDiv>
        {/* Activities */}
        <div className={`flex flex-wrap sm:!flex-nowrap justify-center pt-5 ${GetHeaderFontClass()}`}>
            <ImageButton className='mt-5 sm:!mt-0 w-3/4 sm:!w-1/4 p-2' onClick={(e)=>{history.push(Page.services);GotoTop(e)}} imageSrc="https://lccccompany.blob.core.windows.net/public/mainhall.jpg" imageClassName='object-cover h-full'>{t('service.title')}</ImageButton>
            <ImageButton className='mt-5 sm:!mt-0 w-3/4 sm:!w-1/4 p-2' onClick={(e)=>{history.push(Page.homeGroups);GotoTop(e)}} imageSrc="https://lccccompany.blob.core.windows.net/public/emhall.jpg" imageClassName='object-cover h-full'>{t('homegroup.title')}</ImageButton>
            <ImageButton className='mt-5 sm:!mt-0 w-3/4 sm:!w-1/4 p-2' onClick={(e)=>{history.push(Page.childrenMinistry);GotoTop(e)}} imageSrc="https://lccccompany.blob.core.windows.net/public/churchBuilding.jpg" imageClassName='object-cover h-full'>{t('childrenMinistry.title')}</ImageButton>
            <ImageButton className='mt-5 sm:!mt-0 w-3/4 sm:!w-1/4 p-2' onClick={(e)=>{history.push(Page.studentMinistry);GotoTop(e)}} imageSrc="https://lccccompany.blob.core.windows.net/public/emhall.jpg" imageClassName='object-cover h-full'>{t('studentministry.title')}</ImageButton>
        </div>
        {/* History */}
        <HistoryDiv classNames=''></HistoryDiv>
    </div>
}

const NewsDiv = (props:{children?:any, classNames?:string}) => {
    let [isLoading,setIsLoading] = React.useState<boolean>(false);
    let [feeds, setFeeds] = React.useState<any[]>([]);
    const { t } = useTranslation();

    //On Page Load
    React.useEffect(()=>{
        // GetFeed();
    },[]);

    async function GetFeed(){
        setIsLoading(true);
        // let response = await FaceBookApi.GetChurchFeeds();
        // console.log(response);
        setIsLoading(false);
    }

    return <div className={`p-5 ${props.classNames} bg-gray-100 mt-5`}>
        <div className='flex flex-col md:flex-row items-center'>
            <div className="text-center text-green-600 md:mr-10 p-5 md:mb-10 md:indent-8 drop-shadow-md">
                <FontAwesomeIcon icon={solid('rss')} size="8x"></FontAwesomeIcon>
                <h2 className={`text-center mt-8 ${GetHeaderFontClass()}`}> {t("home.news.title")}</h2>
            </div>
            <div className={`flex-1 justify-center text-center ${GetBodyFontClass()}`}>
                {(!feeds || feeds.length == 0) && <div className='mt-2 text-center'>
                    <p>{t("home.news.empty")}</p>
                </div>}
                <div className='flex items-center justify-center mt-8'>
                    <FacebookIcon href={Brand.contact.facebook} size="2x"></FacebookIcon>
                    <p><a href={Brand.contact.facebook} target="_blank" rel="noreferrer" className="underline text-blue-500 cursor-pointer">{t("home.news.followFb")}</a> <LinkIcon href={Brand.contact.facebook} className=" cursor-pointer"></LinkIcon></p>
                </div>
            </div>
        </div>
    </div>
}

const HistoryDiv = (props:{children?:any, classNames?:string}) => {
    const history = useHistory();
    const { t } = useTranslation();
    return <div className={`${props.classNames} bg-gray-100 mt-5 p-5`}>
        <div className='flex flex-col md:flex-row-reverse items-center'>
            <div className="text-center text-sky-600 md:mr-10 p-5 md:mb-10 md:indent-8 drop-shadow-md">
                <FontAwesomeIcon icon={solid('monument')} size="8x"/>
                <h2 className={`text-center mt-8 ${GetHeaderFontClass()}`}>{t("home.history.title")}</h2>
            </div>
            <div className={`flex-1 justify-center text-center ${GetBodyFontClass()}`}>
                <div className='flex justify-center'>
                    <img src={"https://lccccompany.blob.core.windows.net/public/history1970.png"} className="rounded-full"></img>
                </div>
                <p className={`mt-5`}>{t("ourHistory.subTitle")}</p>
                <p className='mt-5'>
                    <FontAwesomeIcon icon={solid('anchor')} className="text-blue-500 cursor-pointer" onClick={(e:any)=>{history.push(Page.ourHistory);GotoTop(e)}}></FontAwesomeIcon><a onClick={(e:any)=>{history.push(Page.ourHistory);GotoTop(e)}} className="underline text-blue-500 cursor-pointer">{t("home.history.learnMore")}</a>
                </p>
            </div>
        </div>
    </div>
}