import serviceTimetable from "../Const/serviceTimetable.json";
import APIResponse from "../Model/APIResponse";

export default class LCCCCompanyAPI{

    public static async GetSermonTimetable(){
        let url = "https://fake.com/"
        let request:RequestInit = {
            method:"GET",
            headers: {
                'content-type': 'application/json;charset=UTF-8',
            },
            body:null
        }
        return await Promise.resolve(serviceTimetable);
        // return new APIResponse(url, request, response);
    }
}