import { useHistory } from 'react-router-dom';
import { Page } from '../Const/Page';
import { GetBodyFontClass } from '../Helper/Helper';
import '../tw.css';

export default function Brand(props:{className?:string}){
    const history = useHistory();
    return <div className={`flex items-center text-center bg-white ${props.className} cursor-pointer`} onClick={()=>history.push(Page.home)}>
        <div>
            <img src={"https://lccccompany.blob.core.windows.net/public/logo.png"} className="h-16"></img>
        </div>
        <div className='font-HanYiTianYuFeng'>
            <div className='text-stone-600 font-semibold text-lg font-cn_standard' style={{letterSpacing:'0.33rem'}}>理斯特華人基督教會</div>
            <div className={`text-stone-600 font-semibold ${GetBodyFontClass("text-sm")}`}>Leicester Chinese Christian Church</div>
        </div>
    </div>
}