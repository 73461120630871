export default class Sermon{
    start:Date;
    end:Date;
    venue:string;
    language:string;
    speaker:string;
    topic:string;
    scripture:string;

    constructor(){
        this.start = new Date();
        this.end = new Date();
        this.venue = "";
        this.language = "";
        this.speaker = "";
        this.topic = "";
        this.scripture = "";
    }

}