import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { GetHeaderFontClass } from "../Helper/Helper";

export default function PersonCard(props:{imgSrc?:string, title:string, children:any, inverse:boolean, titleClassNames?:string}){
    let imgSrc = props.imgSrc;
    let inverse = props.inverse;
    const [imgFailed,setImgFailed] = React.useState<boolean>(false);
    return <div className="mx-10 md:mx-24 mt-5 mb-24">
        {/* Card */}
        <div className={`flex flex-col ${inverse ? "md:flex-row-reverse" : "md:flex-row"} items-center  rounded-lg p-5 text-black shadow-2xl`}>
            {/* Photo */}
            {imgSrc && !imgFailed ? <img src={imgSrc} className="rounded-full w-36 md:w-48" onError={()=>setImgFailed(true)}/> : <FontAwesomeIcon className="w-36 md:w-48" icon={solid('circle-user')} size="10x"/>}
            {/* Content */}
            <div className={`grow-0 mt-5 md:mt-0 text-center ${inverse ? "md:text-right md:mr-5":"md:text-left md:ml-5"}`}>
                {/* Title */}
                <h3 className={`text-xl md:text-3xl font-bold my-3 ${props.titleClassNames}`}>{props.title}</h3>
                {/* Content */}
                {props.children}
            </div>
        </div>
    </div>
}