export enum Page{
    all="*",
    home="/",
    whoWeAre="/whoWeAre",
    ourHistory="/history",
    ourBelief="/belief",
    ourPastoralTeam="/pastoralTeam",
    ourTrustees="/trustees",
    recruitment="/recruitment",
    annoucement="/annoucement",
    whereToFindUs="/whereToFindUs",
    whatDoWeDo="/whatDoWeDo",
    services="/services",
    engService="/engService",
    chineseService="/chineseService",
    homeGroups="/homeGroups",
    cantoneseHomeGroups="/cantoneseHomeGroups",
    mandarinHomeGroups="/mandarinHomeGroups",
    englishHomeGroups="/englishHomeGroups",
    childrenMinistry="/childrenMinistry",
    youthMinistry="/youthMinistry",
    studentMinistry="/studentMinistry",
    mandarinStudentFellowship="/mandarinStudentFellowship",
    youngAdultFellowship="/youngAdultFellowship",
    donation="/donation",
    privacyPolicy="/privacy",
    disclaminer="/disclaimer"
}