import * as React from 'react';
import '../tw.css';
import '../App.css';
import { AppBar, Toolbar, useScrollTrigger, Box, Fab, Zoom, Button, Menu, MenuItem } from "@material-ui/core";
import { KeyboardArrowUp as KeyboardArrowUpIcon } from "@material-ui/icons";
import Brand from './Brand';
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Page } from '../Const/Page';
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import i18n from '../Local/i18nSetup';
import Language from '../Const/Language';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { BrowserStorageKey } from '../Const/BrowserStorageKey'
import { CantoneseIcon, DonationIcon, EnglishIcon, MandarinIcon } from './Icons';
import { GetBodyFontClass, GetHeaderFontClass, GotoTop } from '../Helper/Helper';
import Style from "../Const/Style.json";
import { LanguageSelector } from './LanguageSelector';

export const BackToTopId = "back-to-top-anchor";

interface ScrollTopProps {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
    children: React.ReactElement;
}
  
function ScrollTop(props: ScrollTopProps) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
      disableHysteresis: true,
      threshold: 100,
    });
    
    return (
      <Zoom in={trigger}>
        <Box
          onClick={GotoTop}
          role="presentation"
          sx={{ position: 'fixed', bottom: 16, right: 16, zIndex:999 }}
        >
          {children}
        </Box>
      </Zoom>
    );
}

export function TopBar(props:{className?:string}){
  const {t} = useTranslation();
  const history = useHistory();

  //Detecting the current language
  let [isLanguageSelectorOpen, setIsLanguageSelectorOpen] = React.useState<boolean>(false);
  let currentLang = i18n.language;
  let currentLangIcon = currentLang == Language.English.Code ? <EnglishIcon className='mr-1'></EnglishIcon> : currentLang == Language.TraditionalChinese.Code ? <CantoneseIcon className='mr-1'></CantoneseIcon> : <MandarinIcon className='mr-1'></MandarinIcon>;
  let currentLangName = currentLang == Language.English.Code ? t('appbar.links.languageSelector.eng') : currentLang == Language.TraditionalChinese.Code ? t('appbar.links.languageSelector.zhhk') : t('appbar.links.languageSelector.zhcn');

  function IsPage(page:string) : boolean{
    return useRouteMatch(page)?.isExact ?? false;
  }
  
  const onLanguageSelect = (code:string) => {
    i18n.changeLanguage(code);
    localStorage.setItem(BrowserStorageKey.Lanaguage,code);
  }

  return <div className={`${props.className} ${GetBodyFontClass("text-lg")}`}>
    {/* Add margin to top */}
    <div className={`relative top-0 mt-24 ${props.className}`}></div>
    {/* Navbar */}
    <nav className="fixed items-center justify-between top-0 w-full flex py-4 bg-white z-50">
      {/* LOGO */}
      <Brand/>
      {/* Items */}
      <div className='ml-5 flex items-center'>
        {/* Home */}
        <NavItem classNames='' active={IsPage(Page.home)} fontAwesomeIcon={solid('church')} link={Page.home} disabled={IsPage(Page.home)}>{t('appbar.links.home')}</NavItem>
        {/* Who are we */}
        <DropDownLinkGroup title={t('whoWeAre.title')} startIcon={<FontAwesomeIcon icon={solid('users')} className="mr-2"/>}>
          {/* Our History */}
          <DropDownLinkGroupItem active={IsPage(Page.ourHistory)} link={Page.ourHistory}><FontAwesomeIcon icon={solid('monument')} className={`mr-3`}/>{t('ourHistory.title')}</DropDownLinkGroupItem>
          {/* Our Belief */}
          <DropDownLinkGroupItem active={IsPage(Page.ourBelief)} link={Page.ourBelief}><FontAwesomeIcon icon={solid('cross')} className={`mr-3`}/>{t('ourBelief.title')}</DropDownLinkGroupItem>
          {/* Pastoral Team */}
          <DropDownLinkGroupItem active={IsPage(Page.ourPastoralTeam)} link={Page.ourPastoralTeam}><FontAwesomeIcon icon={solid('person-praying')} className={`mr-3`}/>{t('pastoralTeam.title')}</DropDownLinkGroupItem>
          {/* Trustees */}
          <DropDownLinkGroupItem active={IsPage(Page.ourTrustees)} link={Page.ourTrustees}><FontAwesomeIcon icon={solid('user-tie')} className={`mr-3`}/>{t('trustees.title')}</DropDownLinkGroupItem>
          {/* Donation */}
          <DropDownLinkGroupItem active={IsPage(Page.donation)} link={Page.donation} ><DonationIcon className={`mr-3 ${IsPage(Page.donation) ? Style.Navbar.ActiveTextColorClass : Style.Navbar.TextColorClass}`}/>{t('supportUs.title')}</DropDownLinkGroupItem>
        </DropDownLinkGroup>
        {/* What do we do */}
        <DropDownLinkGroup title={t('whatDoWeDo.title')} startIcon={<FontAwesomeIcon icon={solid('dice-d6')} className="mr-2"/>}>
          {/* Services */}
          <DropDownLinkGroupItem active={IsPage(Page.services)} link={Page.services}><FontAwesomeIcon icon={solid('person-praying')} className={`mr-3`}/>{t('service.title')}</DropDownLinkGroupItem>
          {/* Home Groups */}
          <DropDownLinkGroupItem active={IsPage(Page.homeGroups)} link={Page.homeGroups}><FontAwesomeIcon icon={solid('house-user')} className={`mr-3`}/>{t('homegroup.title')}</DropDownLinkGroupItem>
          {/* Children Ministry */}
          <DropDownLinkGroupItem active={IsPage(Page.childrenMinistry)} link={Page.childrenMinistry}><FontAwesomeIcon icon={solid('children')} className={`mr-3`}/>{t('childrenMinistry.title')}</DropDownLinkGroupItem>
          {/* Youth Ministry */}
          <DropDownLinkGroupItem active={IsPage(Page.youthMinistry)} link={Page.youthMinistry}><FontAwesomeIcon icon={solid('school')} className={`mr-3`}/>{t('youthMinistry.title')}</DropDownLinkGroupItem>
          {/* Student Ministry */}
          <DropDownLinkGroupItem active={IsPage(Page.studentMinistry)} link={Page.studentMinistry}><FontAwesomeIcon icon={solid('graduation-cap')} className={`mr-3`}/>{t('studentministry.title')}</DropDownLinkGroupItem>
        </DropDownLinkGroup>
        {/* Where to find us */}
        <NavItem active={IsPage(Page.whereToFindUs)} fontAwesomeIcon={solid('map-location-dot')} link={Page.whereToFindUs} disabled={IsPage(Page.whereToFindUs)}>{t('wheretofindus.title')}</NavItem>
      </div>
      {/* Langauge Selector */}
      <LanguageSelector></LanguageSelector>
    </nav>
  </div>;
}


const NavItem = (props:{classNames?:string, active:boolean, fontAwesomeIcon:IconProp, link:string, disabled?:boolean, children:any}) => {
  const history = useHistory();
  let isActive = props.active;
  let icon = props.fontAwesomeIcon;
  let link = props.link;
  return <a className={`px-2 py-2.5 font-medium leading-tight rounded hover:scale-105  hover:outline-none hover:ring-0 transition duration-150 cursor-pointer ease-in-out ${isActive ? Style.Navbar.ActiveTextColorClass:Style.Navbar.TextColorClass} ${props.classNames}`} onClick={(e:any)=>{history.push(link);GotoTop(e)}} href="#">
    <FontAwesomeIcon icon={icon} className={`mr-2 ${isActive ? Style.Navbar.ActiveTextColorClass:Style.Navbar.TextColorClass}`}/>{props.children}
  </a>;
}


const DropDownLinkGroup = (props:{title:string, startIcon?:JSX.Element, children?:any}) => {
  let [isOpen, setIsOpen] = React.useState<boolean>(false);
  let carret = isOpen?solid('caret-up'):solid('caret-down');
  return <div className="flex justify-center">
  <div>
    <div className="dropdown relative" onMouseLeave={()=>setTimeout(()=>setIsOpen(false),0)}>
      <a className={`dropdown-toggle px-2 py-2.5 font-medium leading-tight rounded hover:outline-none hover:ring-0 transition duration-150 ease-in-out flex items-center whitespace-nowrap ${Style.Navbar.TextColorClass}`} href="#" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false" onMouseEnter={()=>setIsOpen(true)} >
        {props.startIcon}{props.title} <FontAwesomeIcon icon={carret} className="ml-2"></FontAwesomeIcon>
      </a>
      <ul onMouseEnter={()=>setIsOpen(true)}  className={`dropdown-menu min-w-max absolute bg-white z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-0 m-0 bg-clip-padding border-none ${!isOpen ? "hidden": "block"}`} aria-labelledby="dropdownMenuButton2">
        {props.children}
      </ul>
    </div>
  </div>
</div>
}
  
const DropDownLinkGroupItem = (props:{children?:any, active:boolean, link:string}) => {
  const history = useHistory();
  let isActive = props.active;
  let link = props.link;
  return <li>
    <a className={`dropdown-item py-2 px-4 font-medium block w-full whitespace-nowrap hover:scale-105 ${isActive ? Style.Navbar.ActiveTextColorClass:Style.Navbar.TextColorClass} transition duration-150 ease-in-out break-all cursor-pointer`} onClick={(e:any)=>{history.push(link);GotoTop(e)}}>{props.children}</a>
  </li>
}

export function BottomBar(props:{className:string}){
  const history = useHistory();
  const {t} = useTranslation();
  function IsPage(page:string) : boolean{
    return useRouteMatch(page)?.isExact ?? false;
  }
  return <div>
    {/* Scroll to TOP */}
    <ScrollTop>
      <Fab className={`${props.className} !bottom-24`} color="secondary" size="small" aria-label="scroll back to top">
        <KeyboardArrowUpIcon />
      </Fab>
    </ScrollTop>
    {/* Add margin to bottom */}
    <div className={`relative bottom-0 mt-36 ${props.className}`}></div>
    {/* Bottom Bar */}
    <div className={`fixed bottom-0 w-full flex bg-white py-2 border-t-2 border-slate-200 ${props.className} ${GetBodyFontClass("text-base")}`}>
      {/* Home Icon */}
      <div className={`flex-1 flex-row font-medium text-center self-center items-center ${IsPage(Page.home) ? Style.Navbar.ActiveTextColorClass : Style.Navbar.TextColorClass} cursor-pointer hover:scale-105 transition duration-150 ease-in-out`} onClick={(e)=>{history.push(Page.home);GotoTop(e)}}>
        <FontAwesomeIcon icon={solid('church')}/>
        <p>{t('appbar.links.home')}</p>
      </div>
      {/* Who we are Icon */}
      <div className={`flex-1 flex-row font-medium text-center self-center items-center ${IsPage(Page.whoWeAre) ? Style.Navbar.ActiveTextColorClass : Style.Navbar.TextColorClass} cursor-pointer border-l-2 hover:scale-105 transition duration-150 ease-in-out`} onClick={(e)=>{history.push(Page.whoWeAre);GotoTop(e)}}>
        <FontAwesomeIcon icon={solid('users')}/>
        <p>{t('whoWeAre.title')}</p>
      </div>
      {/* What do we do Icon */}
      <div className={`flex-1 flex-row font-medium text-center self-center items-center ${IsPage(Page.whatDoWeDo) ? Style.Navbar.ActiveTextColorClass : Style.Navbar.TextColorClass} cursor-pointer border-l-2 hover:scale-105 transition duration-150 ease-in-out`} onClick={(e)=>{history.push(Page.whatDoWeDo);GotoTop(e)}}>
        <FontAwesomeIcon icon={solid('dice-d6')}/>
        <p>{t('whatDoWeDo.title')}</p>
      </div>
      {/* Where to find Us Icon */}
      <div className={`flex-1 flex-row font-medium text-center self-center items-center ${IsPage(Page.whereToFindUs) ? Style.Navbar.ActiveTextColorClass : Style.Navbar.TextColorClass} cursor-pointer border-l-2 hover:scale-105 transition duration-150 ease-in-out`} onClick={(e)=>{history.push(Page.whereToFindUs);GotoTop(e)}}>
        <FontAwesomeIcon icon={solid('map-location-dot')}/>
        <p>{t('wheretofindus.title')}</p>
      </div>
    </div>
  </div>
}