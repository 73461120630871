import { useTranslation } from "react-i18next";
import Card from "../Components/Card";
import ImgCenteredCaption from "../Components/ImgCenteredCaption";
import { GetBodyFontClass, GetHeaderFontClass } from "../Helper/Helper";
import Data from "../Local/en/Translation.json";

export default function CantoneseHomeGroup(){
    const { t } = useTranslation();
    let groupData = Data.homegroup.languages.cantonese.groups;
    let groups = Object.keys(groupData);
    return <div className='mb-10'>
        <ImgCenteredCaption imageSrc={"https://lccccompany.blob.core.windows.net/public/churchBuilding.jpg"} alt="Church Building">
            <h1 className={`md:text-6xl text-2xl text-center text-white ${GetHeaderFontClass()}`}>{t('homegroup.languages.cantonese.name')}</h1>
            <p className={`md:text-2xl text-lg text-center text-white mt-5 ${GetBodyFontClass()}`}>{t('homegroup.languages.cantonese.subTitle')}</p>
        </ImgCenteredCaption>
        <div className='flex justify-center flex-wrap mt-5'>
            {groups && groups.map(name=><Card classNames='mx-3 mb-3 w-full sm:w-2/4 md:w-2/3' imageSrc="https://lccccompany.blob.core.windows.net/public/churchBuilding.jpg">
                <h3 className={`text-lg md:text-2xl text-center ${GetHeaderFontClass()}`}>{t(`homegroup.languages.cantonese.groups.${name}.name`)}</h3>
            </Card>)}
        </div>
    </div>
}