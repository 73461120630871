import * as React from 'react';
import '../tw.css';
import { useTranslation } from "react-i18next";
import ImageButton from '../Components/ImageButton';
import { useHistory } from 'react-router-dom';
import { Page } from '../Const/Page';
import { GetHeaderFontClass, GotoTop } from '../Helper/Helper';

export default function WhoWeArePage(){
    const history = useHistory();
    const { t } = useTranslation();
    return <div className='mb-10 flex flex-col md:flex md:flex-row'>
        <div className='p-10'>
            <ImageButton className={`w-full ${GetHeaderFontClass()}`} imageSrc='https://lccccompany.blob.core.windows.net/public/churchBuilding.jpg' onClick={(e)=>{history.push(Page.ourHistory);GotoTop(e)}}>{t("ourHistory.title")}</ImageButton>
        </div>
        <div className='p-10'>
            <ImageButton className={`w-full ${GetHeaderFontClass()}`} imageSrc='https://lccccompany.blob.core.windows.net/public/ourBeliefTop.jpg' onClick={(e)=>{history.push(Page.ourBelief);GotoTop(e)}}>{t("ourBelief.title")}</ImageButton>
        </div>
        <div className='p-10'>
            <ImageButton className={`w-full ${GetHeaderFontClass()}`} imageSrc='https://lccccompany.blob.core.windows.net/public/pastoralTeam.jpg' onClick={(e)=>{history.push(Page.ourPastoralTeam);GotoTop(e)}}>{t("pastoralTeam.title")}</ImageButton>
        </div>
        <div className='p-10'>
            <ImageButton className={`w-full ${GetHeaderFontClass()}`} imageSrc='https://lccccompany.blob.core.windows.net/public/trustees.jpg' onClick={(e)=>{history.push(Page.ourTrustees);GotoTop(e)}}>{t("trustees.title")}</ImageButton>
        </div>
        <div className='p-10'>
            <ImageButton className={`w-full `} imageSrc='https://lccccompany.blob.core.windows.net/public/churchBuilding.jpg' onClick={(e)=>{history.push(Page.donation);GotoTop(e)}}>{t("supportUs.title")}</ImageButton>
        </div>
    </div>
}
