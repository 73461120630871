import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function Carousel(props:{classNames?:string, imageSources:string[]}){
    let [currentSlide, setCurrentSlide] = React.useState<number>(0);
    const activeColor = "white";
    const inactiveColor = `slate-200/25`;

    return <div id="carouselExampleCaptions" className={`relative ${props.classNames}`} data-bs-ride="carousel">
        {/* Bottom Indicators */}
        <div className="absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4 z-40 list-none" style={{marginLeft:"15%",marginRight:"15%"}}>
            {props.imageSources && props.imageSources.map((x,index)=>{
                return <button type="button" className={`box-content flex-initial w-10 h-1 p-0 mx-1 cursor-pointer  bg-clip-padding border-t-4 ${currentSlide == index ? `border-${activeColor}`:`border-${inactiveColor}`}`} style={{textIndent:"-999px"}} aria-current="true" aria-label={`Slide ${index+1}`} onClick={()=>setCurrentSlide(index)}></button>
            })}
        </div>
        {/* Picture */}
        <div className="relative w-full overflow-hidden">
            <div className={`relative float-left w-full`}>
                <div className="relative overflow-hidden bg-no-repeat bg-cover" style={{backgroundPosition: "50%", backfaceVisibility:'hidden'}}>
                    <img key={currentSlide} src={props.imageSources[currentSlide]} className="block w-full object-cover transition duration-150 ease-in-out" style={{maxHeight:'40vh'}}/>
                </div>
            </div>
        </div>
        {/* Left and Right button */}
        <button className={`absolute top-0 bottom-0 flex items-center justify-center p-0 text-center left-0 z-10 outline-none group ml-8 text-${inactiveColor} hover:text-${activeColor}`} type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev" onClick={()=>currentSlide == 0 ? setCurrentSlide(props.imageSources.length-1) : setCurrentSlide(currentSlide-1)}>
            <span className={`inline-block bg-no-repeat !text-${inactiveColor} group-hover:text-${activeColor}`} aria-hidden="true"><FontAwesomeIcon icon={solid('angle-left')} size="3x"></FontAwesomeIcon></span>
            <span className="sr-only">Previous</span>
        </button>
        <button className={`absolute top-0 bottom-0 flex items-center justify-center p-0 text-center right-0 z-10 outline-none group mr-8 text-${inactiveColor} hover:text-${activeColor}`} type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next" onClick={()=>currentSlide == props.imageSources.length-1 ? setCurrentSlide(0) : setCurrentSlide(currentSlide+1)}>
            <span className={`inline-block bg-no-repeat !text-${inactiveColor} group-hover:text-${activeColor}`} aria-hidden="true"><FontAwesomeIcon icon={solid('angle-right')} size="3x" className=""></FontAwesomeIcon></span>
            <span className="sr-only">Next</span>
        </button>
    </div>
}