import Carousel from "./Carousel"

export enum ImagePosition{
  left=0,
  right=1
}

export default function Card(props:{classNames?:string, imageSrc?:string, imagePosition?:ImagePosition, children:any}){
    let imgPos = props.imagePosition ? props.imagePosition : ImagePosition.left
    return <div className={`${props.classNames} flex flex-col md:flex-row rounded-lg bg-white shadow-md`}>
      {props.imageSrc && imgPos === ImagePosition.left && <img className="w-full h-96 md:h-auto object-cover md:w-48 rounded-t-lg md:rounded-none md:rounded-l-lg" src={props.imageSrc} alt="" />}
      <div className="p-6 flex flex-col justify-start w-full">
        {props.children}
      </div>
      {props.imageSrc && imgPos === ImagePosition.right && <img className="w-full h-96 md:h-auto object-cover md:w-48 rounded-t-lg md:rounded-none md:rounded-l-lg" src={props.imageSrc} alt="" />}
    </div>
}

export function CardCarousel(props:{classNames?:string, imageSources:string[], children:any}){
  return <div className={`${props.classNames} flex flex-col rounded-lg bg-white shadow-md`}>
    {props.imageSources && <Carousel imageSources={props.imageSources} classNames="rounded-t-lg md:rounded-none md:rounded-l-lg"></Carousel>}
    {/* <img className="w-full h-96 md:h-auto object-cover md:w-48 " src={props.imageSrc} alt="" /> */}
    <div className="p-6 flex flex-col justify-start w-full">
      {props.children}
    </div>
  </div>
}