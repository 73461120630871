import * as React from 'react';
import '../tw.css';
import { useTranslation } from "react-i18next";
import ImgCenteredCaption from '../Components/ImgCenteredCaption';
import Card from '../Components/Card';
import { MapIcon, TimeIcon } from '../Components/Icons';
import { GetBodyFontClass, GetHeaderFontClass } from '../Helper/Helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

export default function YouthMinistryPage(){
    const { t } = useTranslation();
    return <div className='mb-10'>
        <ImgCenteredCaption imageSrc={"https://lccccompany.blob.core.windows.net/public/churchBuilding.jpg"} alt="Church Building">
            <h1 className={`md:text-6xl text-2xl text-center text-white ${GetHeaderFontClass()}`}>{t('youthMinistry.title')}</h1>
            <p className={`md:text-2xl text-lg text-center text-white mt-5 ${GetBodyFontClass()}`}>{t('youthMinistry.subTitle')}</p>
        </ImgCenteredCaption>
        <div className='flex justify-center mt-5 mx-3 md:mx-10'>
            <Card>
                <h3 className={`text-center font-bold ${GetHeaderFontClass()}`}><FontAwesomeIcon icon={solid('school')} className={`mr-3`}/>{t(`youthMinistry.sundaySchool.title`)}</h3>
                <h5 className={`text-center mt-3 ${GetBodyFontClass()}`}>{t(`youthMinistry.sundaySchool.subtitle`)}</h5>
                <p className={`text-center mt-3 ${GetBodyFontClass()}`}><TimeIcon/>{t(`youthMinistry.sundaySchool.time`)}</p>
                <p className={`text-center mt-3 ${GetBodyFontClass()}`}><MapIcon/>{t(`youthMinistry.sundaySchool.venue`)}</p>
            </Card>
        </div>
    </div>
}
