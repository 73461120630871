import '../tw.css';
import { useTranslation } from "react-i18next";
import ImageButton from '../Components/ImageButton';
import { GetHeaderFontClass, GotoTop } from '../Helper/Helper';
import { useHistory } from 'react-router-dom';
import { Page } from '../Const/Page';
import { BackToTopId } from '../Components/AppBar';

export default function WhatDoWeDoPage(){
    const history = useHistory();
    const { t } = useTranslation();
    return <div className='mb-10 flex flex-col md:flex md:flex-row'>
        <div className='p-10'>
            <ImageButton className={`w-full ${GetHeaderFontClass()}`} imageSrc='https://lccccompany.blob.core.windows.net/public/churchBuilding.jpg' onClick={(e)=>{history.push(Page.services);GotoTop(e)}}>{t("service.title")}</ImageButton>
        </div>
        <div className='p-10'>
            <ImageButton className={`w-full ${GetHeaderFontClass()}`} imageSrc='https://lccccompany.blob.core.windows.net/public/churchBuilding.jpg' onClick={(e)=>{history.push(Page.homeGroups);GotoTop(e)}}>{t("homegroup.title")}</ImageButton>
        </div>
        <div className='p-10'>
            <ImageButton className={`w-full ${GetHeaderFontClass()}`} imageSrc='https://lccccompany.blob.core.windows.net/public/churchBuilding.jpg' onClick={(e)=>{history.push(Page.childrenMinistry);GotoTop(e)}}>{t("childrenMinistry.title")}</ImageButton>
        </div>
        <div className='p-10'>
            <ImageButton className={`w-full ${GetHeaderFontClass()}`} imageSrc='https://lccccompany.blob.core.windows.net/public/churchBuilding.jpg' onClick={(e)=>{history.push(Page.youthMinistry);GotoTop(e)}}>{t("youthMinistry.title")}</ImageButton>
        </div>
        <div className='p-10'>
            <ImageButton className={`w-full ${GetHeaderFontClass()}`} imageSrc='https://lccccompany.blob.core.windows.net/public/churchBuilding.jpg' onClick={(e)=>{history.push(Page.studentMinistry);GotoTop(e)}}>{t("studentministry.title")}</ImageButton>
        </div>
    </div>
}
