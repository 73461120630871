import { ReactElement } from "react"
import { useTranslation } from "react-i18next";
import Language from "../Const/Language";
import i18n from "../Local/i18nSetup";

export function TimelineAlt(props:{className?:string, children:any}){
    return <ol className={`border-l-2 border-blue-600 ${props.className} w-4/5 md:w-3/5 lg-2/5`}>
        {props.children}
    </ol>
}


export function TimelineAltItem(props:{start:Date, end?:Date, title:string, children:any, dateClassNames?:string, titleClassNames?:string}){
    let currentLang = i18n.language;
    const { t } = useTranslation();
    let start = new Date(props.start);
    let end = props.end ? new Date(props.end) : null;
    let dateFormat:Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    let date = currentLang == Language.English.Code ? start.toLocaleDateString('en-GB', dateFormat) : currentLang == Language.TraditionalChinese.Code ? start.toLocaleDateString('zh-HK', dateFormat) : start.toLocaleDateString('zh-CN', dateFormat);
    let startTime = `${start.getHours()}:${start.getMinutes() == 0 ? "00" : start.getMinutes()}`;
    let endTime = end != null ? `${end.getHours()}:${end.getMinutes() == 0 ? "00" : end.getMinutes()}` : '';
    return <li className="">
        {/* Circle and date*/}
        <div className="flex flex-start items-center pt-3">
            <div className="bg-blue-600 w-4 h-4 flex items-center justify-center rounded-full -ml-2 mr-3 -mt-2"></div>
            <p className={`text-blue-600 text-lg md:text-xl ${props.dateClassNames}`}>{date}</p>
        </div>
        {/* body */}
        <div className="mt-0.5 ml-4 mb-6 text-gray-800 bg-slate-200 rounded-lg p-5 text-center">
            <p className={`text-lg md:text-xl ${props.dateClassNames}`}>{startTime}{end ? ` - ${endTime}`:""}</p>
            <h4 className={`font-semibold text-2xl md:text-4xl mb-1.5 ${props.titleClassNames}`}>{props.title}</h4>
            {props.children}
        </div>
    </li>
}