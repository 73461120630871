import * as React from 'react';
import '../tw.css';
import { useTranslation } from "react-i18next";
import ImgCenteredCaption from '../Components/ImgCenteredCaption';
import Card from '../Components/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { MapIcon, TimeIcon } from '../Components/Icons';
import { GetBodyFontClass, GetHeaderFontClass } from '../Helper/Helper';

export default function ChildrenMinistryPage(){
    const { t } = useTranslation();
    return <div className='mb-10'>
        <ImgCenteredCaption imageSrc={"https://lccccompany.blob.core.windows.net/public/churchBuilding.jpg"} alt="Church Building">
            <h1 className={`md:text-6xl text-2xl text-center text-white ${GetHeaderFontClass()}`}>{t('childrenMinistry.title')}</h1>
            <p className={`md:text-2xl text-lg text-center text-white mt-5 ${GetBodyFontClass()}`}>{t('childrenMinistry.subTitle')}</p>
        </ImgCenteredCaption>
        <div className='flex justify-center mt-5 mx-3 md:mx-10'>
            <Card>
                <h2 className={`text-center font-bold ${GetHeaderFontClass()}`}><FontAwesomeIcon icon={solid('children')} className={`mr-3`}/>{t(`childrenMinistry.sundaySchool.title`)}</h2>
                <h3 className={`text-center mt-3 ${GetBodyFontClass()}`}>{t(`childrenMinistry.sundaySchool.subtitle`)}</h3>
                <p className={`text-center mt-3 ${GetBodyFontClass()}`}><TimeIcon/>{t(`childrenMinistry.sundaySchool.time`)}</p>
                <p className={`text-center mt-3 ${GetBodyFontClass()}`}><MapIcon/>{t(`childrenMinistry.sundaySchool.venue`)}</p>
            </Card>
        </div>
    </div>
}
