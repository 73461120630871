import '../tw.css';
import { useTranslation } from "react-i18next";
import ImgCenteredCaption from '../Components/ImgCenteredCaption';
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ListItem from '../Components/ListItem';
import Quotes from '../Components/Quotes';
import { GetBodyFontClass, GetHeaderFontClass } from '../Helper/Helper';

export default function OurBeliefPage(){
    const { t } = useTranslation();
    let doctrines = [t('ourBelief.doctrines.1'),t('ourBelief.doctrines.2'),t('ourBelief.doctrines.3'),t('ourBelief.doctrines.4'),t('ourBelief.doctrines.5'),t('ourBelief.doctrines.6'),t('ourBelief.doctrines.7'),t('ourBelief.doctrines.8'),t('ourBelief.doctrines.9'),t('ourBelief.doctrines.10'),t('ourBelief.doctrines.11')];
    return <div className='mb-10'>
        <ImgCenteredCaption imageSrc={"https://lccccompany.blob.core.windows.net/public/ourBeliefTop.jpg"} alt="Image of a cross">
            <h1 className={`md:text-6xl text-2xl text-center text-white ${GetHeaderFontClass()}`}>{t('ourBelief.title')}</h1>
            <p className={`md:text-2xl text-lg text-center text-white mt-5 ${GetBodyFontClass()}`}>{t('ourBelief.subTitle')}</p>
        </ImgCenteredCaption>
        <div className='mx-10 lg:mx-20'>
            {/* Doctrines */}
            {doctrines && doctrines.map(doc => { 
                return <ListItem icon={<FontAwesomeIcon icon={solid('play')}/>}>
                    <p className={`${GetBodyFontClass()}`}>{doc}</p>
                </ListItem>;
                })
            }
            {/* Disclaimer */}
            <Quotes classNames='sm:mx-10 md:mx-24 lg:mx-48'>
                <div className='flex items-center'>
                    <FontAwesomeIcon className='flex-none mr-5' icon={solid('circle-exclamation')} size="2x"/>
                    <p className={`flex-initial ${GetBodyFontClass()}`}>{t('ourBelief.disclaimer')}</p>
                </div>
            </Quotes>
        </div>
    </div>
}
