import '../tw.css';
import { useTranslation } from "react-i18next";
import ImgCenteredCaption from '../Components/ImgCenteredCaption';
import Timeline, { TimelineItem, TimelinePosition } from '../Components/Timeline';
import { GetBodyFontClass, GetHeaderFontClass } from '../Helper/Helper';

export default function OurHistoryPage(){
    const { t } = useTranslation();
    let items = [
        {img:"https://lccccompany.blob.core.windows.net/public/history2023aug.jpg",year:"2023 Aug",title:t('ourHistory.history.2023aug_title'),description:t('ourHistory.history.2023aug')},
        {img:"https://lccccompany.blob.core.windows.net/public/history2022may.jpeg",year:"2022 May",title:t('ourHistory.history.2022may_title'),description:t('ourHistory.history.2022may')},
        {img:"https://lccccompany.blob.core.windows.net/public/history2022mar.png",year:"2022 Mar",title:t('ourHistory.history.2022mar_title'),description:t('ourHistory.history.2022mar')},
        // Add SOP
        {img:"",year:"2022 Apr",title:t('ourHistory.history.2022apr_title'),description:t('ourHistory.history.2022apr')},
        {img:"",year:"2021 Jul",title:t('ourHistory.history.2021jul_title'),description:t('ourHistory.history.2021jul')},
        {img:"https://lccccompany.blob.core.windows.net/public/history2021may.jpg",year:"2021 May",title:t('ourHistory.history.2021may_title'),description:t('ourHistory.history.2021may')},
        {img:"",year:"2020 Mar",title:t('ourHistory.history.2020mar_title'),description:t('ourHistory.history.2020mar')},
        {img:"https://lccccompany.blob.core.windows.net/public/history2019jul.jpg",year:"2019 Jul",title:t('ourHistory.history.2019jul_title'),description:t('ourHistory.history.2019jul')},
        {img:"https://lccccompany.blob.core.windows.net/public/history2018sep.jpg",year:"2018 Sep",title:t('ourHistory.history.2018sep_title'),description:t('ourHistory.history.2018sep')},
        {img:"https://lccccompany.blob.core.windows.net/public/history2017jul9.jpg",year:"2017 Jul",title:t('ourHistory.history.2017jul9_title'),description:t('ourHistory.history.2017jul9')},
        {img:"",year:"2017 Jun",title:t('ourHistory.history.2017jun_title'),description:t('ourHistory.history.2017jun')},
        {img:"",year:"2016",title:t('ourHistory.history.2016_title'),description:t('ourHistory.history.2016')},
        {img:"https://lccccompany.blob.core.windows.net/public/history2015aug.jpg",year:"2015 Aug",title:t('ourHistory.history.2015aug_title'),description:t('ourHistory.history.2015aug')},
        {img:"",year:"2013 Oct",title:t('ourHistory.history.2013oct_title'),description:t('ourHistory.history.2013oct')},
        {img:"",year:"2012",title:t('ourHistory.history.2012_title'),description:t('ourHistory.history.2012')},
        {img:"",year:"2011 Feb",title:t('ourHistory.history.2011feb_title'),description:t('ourHistory.history.2011feb')},
        {img:"",year:"2010 Dec",title:t('ourHistory.history.2010dec_title'),description:t('ourHistory.history.2010dec')},
        {img:"",year:"2009",title:t('ourHistory.history.2009_2_title'),description:t('ourHistory.history.2009_2')},
        {img:"",year:"2009",title:t('ourHistory.history.2009_1_title'),description:t('ourHistory.history.2009_1')},
        {img:"",year:"2008",title:t('ourHistory.history.2008_title'),description:t('ourHistory.history.2008')},
        {img:"",year:"2005",title:t('ourHistory.history.2005_title'),description:t('ourHistory.history.2005')},
        {img:"https://lccccompany.blob.core.windows.net/public/history2004may.jpg",year:"2004 May",title:t('ourHistory.history.2004may_title'),description:t('ourHistory.history.2004may')},
        {img:"https://lccccompany.blob.core.windows.net/public/history2004mar.jpg",year:"2004 Mar",title:t('ourHistory.history.2004mar_title'),description:t('ourHistory.history.2004mar')},
        {img:"",year:"2002",title:t('ourHistory.history.2002_title'),description:t('ourHistory.history.2002')},
        {img:"https://lccccompany.blob.core.windows.net/public/history2001nov.jpg",year:"2001 Nov",title:t('ourHistory.history.2001nov18_title'),description:t('ourHistory.history.2001nov18')},
        {img:"https://lccccompany.blob.core.windows.net/public/history2001.jpg",year:"2001",title:t('ourHistory.history.2001_title'),description:t('ourHistory.history.2001')},
        {img:"https://lccccompany.blob.core.windows.net/public/history1999_02.png",year:"1999",title:t('ourHistory.history.1999_02_title'),description:t('ourHistory.history.1999_02')},
        {img:"https://lccccompany.blob.core.windows.net/public/churchBuilding2.jpg",year:"1999",title:t('ourHistory.history.1999_title'),description:t('ourHistory.history.1999')},
        {img:"https://lccccompany.blob.core.windows.net/public/history1998.jpg",year:"1998",title:t('ourHistory.history.1998_title'),description:t('ourHistory.history.1998')},
        {img:"https://lccccompany.blob.core.windows.net/public/history1995_96.png",year:"1995-96",title:t('ourHistory.history.1995_96_title'),description:t('ourHistory.history.1995_96')},
        {img:"",year:"1993 Aug",title:t('ourHistory.history.1993aug_title'),description:t('ourHistory.history.1993aug')},
        {img:"",year:"1992",title:t('ourHistory.history.1992_title'),description:t('ourHistory.history.1992')},
        {img:"https://lccccompany.blob.core.windows.net/public/history1990_99.jpg",year:"1990-99",title:t('ourHistory.history.1990_99_title'),description:t('ourHistory.history.1990_99')},
        {img:"https://lccccompany.blob.core.windows.net/public/history1980_90.png",year:"1980-90",title:t('ourHistory.history.1980_90_title'),description:t('ourHistory.history.1980_90')},
        {img:"https://lccccompany.blob.core.windows.net/public/history1989.png",year:"1989",title:t('ourHistory.history.1989_title'),description:t('ourHistory.history.1989')},
        {img:"https://lccccompany.blob.core.windows.net/public/history1988.png",year:"1988",title:t('ourHistory.history.1988_title'),description:t('ourHistory.history.1988')},
        {img:"",year:"1988 Aug",title:t('ourHistory.history.1988aug_title'),description:t('ourHistory.history.1988aug')},
        {img:"https://lccccompany.blob.core.windows.net/public/history1970.png",year:"1970",title:t('ourHistory.history.1970_title'),description:t('ourHistory.history.1970')}
    ];
    return <div className='mb-10'>
        <ImgCenteredCaption imageSrc={"https://lccccompany.blob.core.windows.net/public/churchBuilding.jpg"} alt="Church Building">
            <h1 className={`md:text-6xl text-2xl text-center text-white ${GetHeaderFontClass()}`}>{t('ourHistory.title')}</h1>
            <p className={`md:text-2xl text-lg text-center text-white mt-5 ${GetBodyFontClass()}`}>{t('ourHistory.subTitle')}</p>
        </ImgCenteredCaption>
        {items && <div className='mt-5'>
            {items.map((item,index) => {
                return <Timeline position={TimelinePosition.left} key={index}>
                    <TimelineItem year={item.year} title={item.title} titleClassNames={GetHeaderFontClass()} yearClassNames={GetBodyFontClass()}>
                        {item.img && <img src={item.img} className="rounded-lg" style={{maxWidth:"500px"}}></img>}
                        <p className={GetBodyFontClass()}>{item.description}</p>
                    </TimelineItem>
                </Timeline>;
            })}
        </div>}
    </div>
}
