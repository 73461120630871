export default function ImageButton(props:{className?:string, imageClassName?:string, imageSrc:string, children:any, onClick?:(e:any)=>any}){
    return <div className={`relative text-center ${props.className} group hover:border-2 hover:border-solid hover:border-stone-500 hover:rounded-lg cursor-pointer`} onClick={props.onClick}>
        <img src={props.imageSrc} className={`opacity-40 w-full rounded-lg ${props.imageClassName}`}></img>
        <div className='absolute w-full h-full top-0 flex items-center justify-center group-hover:scale-125 ease-in-out duration-700'>
            <div className='bg-gray-800/60 rounded-lg p-5 mx-10 sm:mx-10 md:mx-24 lg:mx-48  text-white text-center'>
                <h3 className='text-lg md:text-2xl text-center' style={{wordBreak:"keep-all"}}>
                    {props.children}
                </h3>
            </div>
        </div>
    </div>
}