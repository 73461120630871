import * as React from 'react';
import '../tw.css';
import { useTranslation } from "react-i18next";
import ImgCenteredCaption from '../Components/ImgCenteredCaption';
import PersonCard from '../Components/PersonCard';
import { GetBodyFontClass, GetHeaderFontClass } from '../Helper/Helper';

export default function PastoralTeamPage(){
    const { t } = useTranslation();
    return <div className='mb-10'>
        <ImgCenteredCaption imageSrc={"https://lccccompany.blob.core.windows.net/public/pastoralTeam.jpg"} alt="Image of Pastor Esther and Elder Andre">
            <h1 className={`md:text-6xl text-2xl text-center text-white ${GetHeaderFontClass()}`}>{t('pastoralTeam.title')}</h1>
            <p className={`md:text-2xl text-lg text-center text-white mt-5 ${GetBodyFontClass()}`}>{t('pastoralTeam.subTitle')}</p>
        </ImgCenteredCaption>
        <PersonCard imgSrc={"https://lccccompany.blob.core.windows.net/public/estherho.jpg"} title={t('pastoralTeam.team.esther.name')} inverse={false} titleClassNames={GetHeaderFontClass()}>
            <p className={GetBodyFontClass()}>{t('pastoralTeam.team.esther.description')}</p>
        </PersonCard>
        <PersonCard imgSrc={"https://lccccompany.blob.core.windows.net/public/joeyip.jpeg"} title={t('pastoralTeam.team.joe.name')} inverse={true} titleClassNames={GetHeaderFontClass()}>
            <p className={GetBodyFontClass()}>{t('pastoralTeam.team.joe.description')}</p>
        </PersonCard>
        <PersonCard imgSrc={"https://lccccompany.blob.core.windows.net/public/johntang.jpg"} title={t('pastoralTeam.team.john.name')} inverse={false} titleClassNames={GetHeaderFontClass()}>
            <p className={GetBodyFontClass()}>{t('pastoralTeam.team.john.description')}</p>
        </PersonCard>
        <PersonCard imgSrc={"https://lccccompany.blob.core.windows.net/public/andreng.jpg"} title={t('pastoralTeam.team.andre.name')} inverse={true} titleClassNames={GetHeaderFontClass()}>
            <p className={GetBodyFontClass()}>{t('pastoralTeam.team.andre.description')}</p>
        </PersonCard>
    </div>
}
