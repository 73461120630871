import React, { useEffect } from 'react';
import Language from '../Const/Language';
import i18n from '../Local/i18nSetup';
import { CantoneseIcon, EnglishIcon, MandarinIcon } from './Icons';
import { useTranslation } from 'react-i18next';
import { BrowserStorageKey } from '../Const/BrowserStorageKey';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import Style from "../Const/Style.json";

export function LanguageSelector(){
    const {t} = useTranslation();
    let [isLanguageSelectorOpen, setIsLanguageSelectorOpen] = React.useState<boolean>(false);
    let currentLang = i18n.language;
    let currentLangIcon = currentLang == Language.English.Code ? <EnglishIcon className='mr-1'></EnglishIcon> : currentLang == Language.TraditionalChinese.Code ? <CantoneseIcon className='mr-1'></CantoneseIcon> : <MandarinIcon className='mr-1'></MandarinIcon>;
    let currentLangName = currentLang == Language.English.Code ? t('appbar.links.languageSelector.eng') : currentLang == Language.TraditionalChinese.Code ? t('appbar.links.languageSelector.zhhk') : t('appbar.links.languageSelector.zhcn');
    
    const onLanguageSelect = (code:string) => {
        i18n.changeLanguage(code);
        localStorage.setItem(BrowserStorageKey.Lanaguage,code);
    }

    return <div className="flex justify-center">
        <div>
          <div className="dropdown relative" onMouseLeave={()=>setTimeout(()=>setIsLanguageSelectorOpen(false),0)}>
            <a className={`dropdown-toggle px-2 py-2.5 ${currentLang == Language.English.Code ? "font-medium" : "font-semibold"} text-lg leading-tight rounded hover:outline-none hover:ring-0 transition duration-150 ease-in-out flex items-center whitespace-nowrap ${Style.Navbar.TextColorClass}`} href="#" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false" onMouseEnter={()=>setIsLanguageSelectorOpen(true)} >
              {currentLangIcon}{currentLangName} <FontAwesomeIcon icon={isLanguageSelectorOpen?solid('caret-up'):solid('caret-down')} className="ml-2"></FontAwesomeIcon>
            </a>
            <ul onMouseEnter={()=>setIsLanguageSelectorOpen(true)}  className={`dropdown-menu min-w-max absolute bg-white z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-0 m-0 bg-clip-padding border-none ${!isLanguageSelectorOpen ? "hidden": "block"} ${currentLang != Language.English.Code ? "-translate-x-8" : ""}`} aria-labelledby="dropdownMenuButton2">
              {/* Eng */}
              {currentLang != Language.English.Code && <li>
                <a className={`dropdown-item py-2 px-4 font-medium block w-full whitespace-nowrap hover:scale-105 transition duration-150 ease-in-out cursor-pointer`} onClick={()=>{onLanguageSelect(Language.English.Code);setIsLanguageSelectorOpen(false)}}><EnglishIcon className='mr-3'></EnglishIcon>{t('appbar.links.languageSelector.eng')}</a>
              </li>}
              {/* HK */}
              {currentLang != Language.TraditionalChinese.Code && <li>
                <a className={`dropdown-item py-2 px-4 font-semibold block w-full whitespace-nowrap hover:scale-105 transition duration-150 ease-in-out cursor-pointer`} onClick={()=>{onLanguageSelect(Language.TraditionalChinese.Code);setIsLanguageSelectorOpen(false)}}><CantoneseIcon className='mr-3'></CantoneseIcon>{t('appbar.links.languageSelector.zhhk')}</a>
              </li>}
              {/* CN */}
              {currentLang != Language.SimplifiedChinese.Code &&  <li>
                <a className={`dropdown-item py-2 px-4 font-semibold block w-full whitespace-nowrap hover:scale-105 transition duration-150 ease-in-out cursor-pointer`} onClick={()=>{onLanguageSelect(Language.SimplifiedChinese.Code);setIsLanguageSelectorOpen(false)}}><MandarinIcon className='mr-3'></MandarinIcon>{t('appbar.links.languageSelector.zhcn')}</a>
              </li>}
            </ul>
          </div>
        </div>
      </div>
}