import * as React from 'react';
import '../tw.css';
import { useTranslation } from "react-i18next";
import ImgCenteredCaption from '../Components/ImgCenteredCaption';
import PersonCard from '../Components/PersonCard';
import { GetBodyFontClass, GetHeaderFontClass } from '../Helper/Helper';
import Data from "../Local/en/Translation.json";

export default function TrusteesPage(){
    const { t } = useTranslation();
    let trusteesData = Data.trustees.team;
    let trustees = Object.keys(trusteesData);

    return <div className='mb-10'>
        <ImgCenteredCaption imageSrc={"https://lccccompany.blob.core.windows.net/public/trustees.jpg"} alt="Trustees">
            <h1 className={`md:text-6xl text-2xl text-center text-white ${GetHeaderFontClass()}`}>{t('trustees.title')}</h1>
            <p className={`md:text-2xl text-lg text-center text-white mt-5 ${GetBodyFontClass()}`}>{t('trustees.subTitle')}</p>
        </ImgCenteredCaption>
        {trustees && trustees.filter(x=>t(`trustees.team.${x}.name`) != "").map((trustee,index) => {
            return <PersonCard title={t(`trustees.team.${trustee}.title`)} inverse={index % 2 == 0} titleClassNames={GetHeaderFontClass()} imgSrc={t(`trustees.team.${trustee}.image`)}>
                <p className={GetBodyFontClass()}>{t(`trustees.team.${trustee}.name`)}</p>
                <p className={GetBodyFontClass()}>{t(`trustees.team.${trustee}.description`)}</p>
            </PersonCard>
        })}
    </div>
}
